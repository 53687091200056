import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { IllustrationCardsBlock } from 'stile-shared/src/components/2020/blocks/IllustrationCardsBlock';
import { TeaserBlock } from 'stile-shared/src/components/2020/blocks/TeaserBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { H2, H3, P } from 'stile-shared/src/components/2020/common/Primitives';
import { VideoModalLauncher } from 'stile-shared/src/components/2020/common/VideoModalLauncher';
import { Icon } from 'stile-shared/src/components/2020/layout/Icon';

import { getPageData } from 'utils/getPageData';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';
import { LabKitButton } from 'components/common/LabKitButton';

import iconBYO from '../../../../stile-shared/assets/icons/icon_byo.svg';
import iconCabinet from '../../../../stile-shared/assets/icons/icon_cabinet.svg';
import iconGeneralKit from '../../../../stile-shared/assets/icons/icon_general_equipment.svg';
import iconGlassware from '../../../../stile-shared/assets/icons/icon_glassware.svg';
import iconRecycle from '../../../../stile-shared/assets/icons/icon_recycle.svg';
import iconShopping from '../../../../stile-shared/assets/icons/icon_shopping.svg';
import iconSpecialMaterials from '../../../../stile-shared/assets/icons/icon_special_materials.svg';
import iconUnitKit from '../../../../stile-shared/assets/icons/icon_unit_kit.svg';

export { Head } from 'templates/2020/Head';

function LabKitPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <VideoModalLauncher
            url="https://youtu.be/NpVxc4Pby04"
            text="See Stile's hands-on labs"
            coverImage={
              <StaticImage
                src="../../../../stile-shared/assets/images/lab-kits/kit_cover.png"
                alt=""
              />
            }
          />
        }
      />

      <TextBlock>
        <P>
          Bring your science classroom to the next level with Stile Lab Kits, where environmental
          responsibility meets effortless teaching. Our kits transform complex scientific concepts
          into accessible, hands-on experiences while ensuring consistent and dependable performance
          for true peace of mind in your classroom.
        </P>
      </TextBlock>

      <IllustrationCardsBlock
        title="Why Stile kits are different"
        variant="beige"
        align="center"
        space={theme.space.xxxl}
        cards={[
          {
            image: (
              <StaticImage
                src="../../../../stile-shared/assets/images/lab-kits/card_less_waste.png"
                alt=""
              />
            ),
            title: 'Less waste',
            text: 'Stile Lab Kits are packaged in SFI-certified, recycled boxes and uphold eco-friendly practices by designing, printing, and packing all materials in the USA to reduce carbon emissions.',
          },
          {
            image: (
              <StaticImage
                src="../../../../stile-shared/assets/images/lab-kits/card_clear_guidance.png"
                alt=""
              />
            ),
            title: 'Crystal clear guidance',
            text: 'Each Stile Lab Kit includes simple guides meticulously crafted to provide educators and students with easy-to-follow, step-by-step instructions and videos.',
          },
          {
            image: (
              <StaticImage
                src="../../../../stile-shared/assets/images/lab-kits/card_just_works.png"
                alt=""
              />
            ),
            title: 'The “Just Works” guarantee',
            text: 'Stile Lab Kits are thoroughly tested for reliability and effectiveness, allowing educators to concentrate on teaching rather than equipment issues.',
          },
        ]}
      />

      <TeaserBlock
        title="Get only what you need by mixing and matching our kits"
        contentSide="right"
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/lab-kits/kit_mockup.png"
            alt=""
          />
        }
      >
        <P>
          Flexibility and choice are crucial in education. Stile Lab Kits address educators’
          concerns about unused lab equipment by providing tailored, efficient, and safe science
          teaching experiences while considering budget and space constraints. Stile offers two
          types of kits: the Base Kit and the Unit Kit.
        </P>
      </TeaserBlock>

      <TextBlock title="Base Kits">
        <P>
          The Base Lab Kit includes two boxes with general science equipment and glassware to ensure
          your teachers have all the hardware needed for success!
        </P>
        <H3>
          <Icon src={iconGeneralKit} text="General Equipment" iconSize="large" />
        </H3>
        <P>
          This is your one-stop solution for setting up a complete grade-level science lab. This kit
          includes everything from measuring devices to lab utensils, supporting all units of study
          for your grade. Durable and reusable, it’s the backbone of your scientific explorations!
        </P>
        <StaticImage
          src="../../../../stile-shared/assets/images/lab-kits/kit_general.png"
          alt=""
          objectFit="contain"
        />
        <H3>
          <Icon src={iconGlassware} text="Glassware" iconSize="large" />
        </H3>
        <P>
          Our glassware kit, tailored for each grade level, meets precision and safety standards. It
          includes all necessary lab glassware cradled in custom foam inserts to ensure secure,
          breakage-free shipping and storage.
        </P>
        <StaticImage
          src="../../../../stile-shared/assets/images/lab-kits/kit_glassware.png"
          alt=""
          objectFit="contain"
        />
      </TextBlock>

      <TextBlock>
        <H2>
          <Icon src={iconUnitKit} text="Unit Kit" iconSize="large" />
        </H2>
        <P>
          These are unit-specific kits with all the specialized materials you need to run the labs
          in each grade’s unit. The materials include consumables and non-consumables, such as
          magnets, craft supplies, batteries, and other specialized materials. This kit supports all
          units taught in this grade level.
        </P>
        <StaticImage
          src="../../../../stile-shared/assets/images/lab-kits/kit_unit.png"
          alt=""
          objectFit="contain"
        />
        <H3>Refill Kit</H3>
        <P>
          Never run out of what you need with our Refills Kit. Designed to replenish the consumable
          materials from both the Unit Materials Kits, it ensures your lab is always prepared for
          the next experiment.
        </P>
      </TextBlock>

      <TextBlock>
        <H2>
          <Icon src={iconBYO} text="BYO Materials" iconSize="large" />
        </H2>
        <P>
          We believe in sustainable science education. That’s why our kits do not include a few
          readily available materials to avoid waste and redundancy. These materials fall into four
          categories:
        </P>
        <P>
          <Icon src={iconCabinet} text=" " iconSize="large" />
          <strong>Classroom Cabinet</strong> – Commonly available materials in most schools
          (pencils, scissors).
        </P>
        <P>
          <Icon src={iconShopping} text=" " iconSize="large" />
          <strong>Shopping List</strong> – Everyday items are easily sourced from local grocery
          stores (strawberries, bread).
        </P>
        <P>
          <Icon src={iconRecycle} text=" " iconSize="large" />
          <strong>Upcycle</strong> – To encourage recycling, teachers and students can repurpose
          these materials (cereal boxes, foam).
        </P>
        <P>
          <Icon src={iconSpecialMaterials} text=" " iconSize="large" />
          <strong>Specialist Materials</strong> – Items that are likely already available in your
          school’s science department (compound microscope, hot plate).
        </P>
      </TextBlock>

      <TextBlock>
        <LabKitButton />
      </TextBlock>

      <NextPageBlock path="/what-is-stile/stile-pl/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default LabKitPage;
